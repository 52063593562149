<template>
  <div class="home">
    <mv-full-page
      :isPointer="true"
      pointerPos="right"
      :isV="isV"
      :pages="pages"
      :page.sync="page"
      :isCache="false"
      :transition="{
        duration: '700ms', // 动画时长
        timingFun: 'ease', // 动画速度曲线
        delay: '0', // 动画延迟
      }"
      @rollEnd="onRollEnd"
      @pointerMouseover="onPointerMouseover"
      :config="config"
    >
      <template #page1>
        <!-- 静态插槽 -->
        <Header></Header>
        <!-- 轮播图 -->
        <div class="block">
          <el-carousel trigger="click" height="80vh">
            <el-carousel-item v-for="(item, i) in bannerss" :key="i">
              <img
                :src="'http://website.yuangtech.com' + item"
                alt=""
                height="100%"
                width="100%"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </template>
      <!-- 选择七星 -->
      <template #page2>
        <div class="adv">
          <p>我们的优势</p>
          <p>OUR ADVANTAGE</p>
          <div class="items">
            <div class="blocks">
              <img src="../assets/qixingp/zhuanzhu@2x.png" alt="" />
              <div class="Word lee">
                <h1>创意引领</h1>
                <h2>拥有一流的创意设计，充分展示展览展会的特点于优势</h2>
              </div>
            </div>
            <div class="blocks">
              <img src="../assets/qixingp/anquan@2x.png" alt="" />
              <div class="Word">
                <h1>品质一流</h1>
                <h2>质量求生存，以信誉求发展</h2>
              </div>
            </div>
            <div class="blocks">
              <img src="../assets/qixingp/zhiliang@2x.png" alt="" />
              <div class="Word">
                <h1>绿色环保</h1>
                <h2>让环保展具进入每一 个展示空间</h2>
              </div>
            </div>
            <div class="blocks">
              <img src="../assets/qixingp/fuwu@2x.png" alt="" />
              <div class="Word">
                <h1>七星服务</h1>
                <h2>七星级服务标准</h2>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 绿色案例 -->
      <template #page3>
        <div class="green">
          <p>绿色展具案例</p>
          <p>EXHIBITION CASE</p>
          <div class="flex">
            <div class="ccde" v-for="v in green" :key="v.id">
              <div class="pic">
                <img :src="'http://website.yuangtech.com' + v.cover" alt="" />
              </div>
              <div class="word">
                <h1>{{ v.title }}</h1>
                <div class="xian"></div>
                <h2 v-html="v.content">&ensp;&ensp;&ensp;&ensp;</h2>
                <span class="details" @click="toDetail(v.id)">查看详情</span>
              </div>
            </div>
          </div>
          <div class="centers">
            <span @click="pushRouter('/exhibition')"
              >查看更多&nbsp;&nbsp;></span
            >
          </div>
        </div>
      </template>
      <!-- 展厅案例 -->
      <template #page4>
        <div class="cases">
          <p>展厅案例</p>
          <p>GALLERY CASE</p>
          <div class="flex">
            <div class="ccde" v-for="v in classic" :key="v.id">
              <div class="pic">
                <img :src="'http://website.yuangtech.com' + v.cover" alt="" />
              </div>
              <div class="word">
                <h1>{{ v.title }}</h1>
                <div class="xian"></div>
                <h2 v-html="v.content">&ensp;&ensp;&ensp;&ensp;</h2>
                <span class="details" @click="toDetail(v.id)">查看详情</span>
              </div>
            </div>
          </div>
          <div class="centers">
            <span @click="pushRouter('/galleryPavilion', 1)">
              查看更多&nbsp;&nbsp;>
            </span>
          </div>
        </div>
      </template>
      <!-- 服务流程 -->
      <template #page5>
        <template>
          <div class="proc">
            <p>展会服务流程</p>
            <p>Exhibition service process</p>
          </div>
        </template>
        <!-- 合作企业 -->
        <template>
          <div class="coop">
            <p>合作企业</p>
            <p>COOPERATIVE ENTERPRISE</p>
            <img src="@/assets/qixingp/qy_logo@2x.png" alt="" />
            <!-- <div>
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
            </div>
            <div>
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
            </div>
            <div>
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
              <img src="@/assets/qixing/矩形 19@2x.png" alt="" />
            </div> -->
          </div>
        </template>
      </template>
      <!-- 新闻中心 -->
      <template #page6>
        <div class="newsc">
          <p>新闻中心</p>
          <p>NEWS CENTER</p>
          <div class="journalism">
            <div
              class="contents"
              v-for="(item, i) in news"
              :key="item.id"
              @click="toDetails(item.id)"
              v-if="i <= 5"
            >
              <div class="time">
                <p>{{ item.day }}</p>
                <span>{{ item.yeah_month }}</span>
              </div>
              <div class="words">
                <h1>{{ item.title }}</h1>
                <div class="Jump">
                  <span v-html="item.content"
                    >今天展厅设计的小编和大家来聊聊展览会中为什么需要
                    注意环保的问题。展览中的不环保因素大多来自搭建...</span
                  >
                  <div class="sanjiao"></div>
                </div>
              </div>
            </div>
            <h3 @click="pushRouter('/newsCenter')">查看更多</h3>
          </div>
        </div>
      </template>
      <template #page7>
        <template>
          <div class="posr clearfix">
            <img src="../assets/qixingp/bj@2x.png" class="service" alt="" />
            <img src="../assets/qixingp/erweima@2x.png" class="post" alt="" />
          </div>
        </template>
        <!-- 设计需求 -->
        <template>
          <div class="demand">
            <div class="fll">
              <img src="../assets/qixingp/sheji@2x.png" class="design" alt="" />
              <span>设计需求:</span>
            </div>
            <div class="disi">
              <div class="wybzd">
                <div class="inp1">
                  <p>您的姓名：</p>
                  <input
                    type="text"
                    placeholder="请在这里输入姓名"
                    v-model="name"
                  />
                </div>
                <div class="inp1">
                  <p>您的电话：</p>
                  <input
                    type="text"
                    placeholder="请在这里输入电话"
                    v-model="phone"
                  />
                </div>
                <div class="inp1">
                  <p>您的邮箱：</p>
                  <input
                    type="text"
                    placeholder="请在这里输入邮箱"
                    v-model="email"
                  />
                </div>
              </div>

              <div class="inp2">
                <p>您的需求：</p>
                <textarea
                  cols="8"
                  rows="20"
                  placeholder="请在这里输入需求"
                  v-model="content"
                  style="resize: none"
                ></textarea>
              </div>
              <span class="fabu" @click="release()">发布</span>
            </div>
          </div>
        </template>
        <!-- 底部 -->
        <div class="bot">
          <div class="top">
            <div class="juzhong">
              <div class="inb">
                <div class="spa marr">
                  <img src="../assets/qixingp/gongsi@2x.png" alt="" />
                  <span>公司名称：青岛七星国际会展科技有限公司</span>
                </div>
                <div class="spa">
                  <img src="../assets/qixingp/telephone@2x.png" alt="" />
                  <span>联系电话：{{ tableData.phone }}</span>
                </div>
                <div class="spa">
                  <img src="../assets/qixingp/dizhi@2x.png" alt="" />
                  <span>公司地址：{{ tableData.address }}</span>
                </div>
                <!-- <div class="spa">
            <span>电话：{{ tableData.phone1 }}</span>
          </div> -->
              </div>
              <div class="shuxian"></div>
              <div class="inb">
                <span>友情链接：</span>
                <a href="http://qdjiapeng.com/" target="_blank">
                  青岛佳朋展览展示公司
                </a>
                <a href="http://zhanju365.com/" target="_blank">
                  青岛玛斯特装饰设计工程
                </a>
                <a href="https://www.yuangtech.com/#/home" target="_blank">
                  青岛元罡科技有限公司
                </a>
              </div>
              <div class="shuxian"></div>
              <div class="inb">
                <div class="in_block">
                  <p>共享展具小程序</p>
                  <img src="@/assets/qixingp/矩形 942小程序二维码.png" alt="" />
                </div>
                <div class="in_block">
                  <p>七星公众号</p>
                  <img src="@/assets/qixingp/gongzhonghao@2x.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            Copyright© 青岛七星国际会展科技有限公司 版权所有
            <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备15022445号-2</a>
          </div>
        </div>
      </template>
    </mv-full-page>
  </div>
</template>

<script>
import Header from "@/components/header1";
import bottom from "@/components/bottom";
import getCate from "@/utils/getCate";
export default {
  name: "index",
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: "",
      list: "",
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
      pages: 7,
      tempPage: 2,
      isV: true,
      page: 1,
      name: "",
      phone: "",
      email: "",
      content: "",
      bannerss: [],
      green: [],
      classic: [],
      news: [],
      pag3: [],
      pag4: [],
      pag5: [],
      typeList: [],
      config: {
        // 自动播放
        autoPlay: false,
        //  循环播放
        loop: false,
        // 切换间隔
        interval: 1000,
      },
    };
  },
  created() {
    this.getData();
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      window.location.href = "/index"; //跳转到手机
    } else {
      // window.location.href = "/index_p";
    }
    //自动加载banner方法
    this.banner();
    this.cases();
    this.newss();
  },
  methods: {
    pushRouter(item) {
      this.$router.push(item);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_company_phone", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data;
            console.log("电话", that.tableData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toDetail(id) {
      this.$router.push({
        path: "/exhibitionDetail",
        query: { item: id },
      });
    },
    toDetails(id) {
      this.$router.push({ path: "/newsDetail", query: { item: id } });
    },
    banner() {
      const that = this;
      const data = {};
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/qx_banner_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            console.log("ress", res.data);
            that.bannerss = res.data.data;
            // "?v=" +
            // Math.round(Math.random() * 5);
            // that.bannerss.push(url);
            console.log("that.bannerss", that.bannerss);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    release() {
      const that = this;
      const data = {
        name: that.name,
        phone: that.phone,
        email: that.email,
        content: that.content,
      };
      // cate_id: that.tabIndex,
      if (
        that.name == "" &&
        that.phone == "" &&
        that.email == "" &&
        that.content == ""
      ) {
        this.$message({
          message: "您尚未填写完整！",
          type: "warning",
        });
      } else {
        that.$http
          .postApi("/qx_need_add", data)
          .then((res) => {
            if (res.data.code === 200) {
              that.name = "";
              that.phone = "";
              that.email = "";
              that.content = "";
              this.$message({
                message: "发布成功",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    cases() {
      const that = this;
      const data = {};
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/qx_home_case", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res.data.data, "222");
            res.data.data.green.map((item) => {
              item.content = item.content
                .replace(/\s*/g, "")
                .replace(/<[^>]+>/g, "")
                .replace(/↵/g, "")
                .replace(/[\r\n]/g, "")
                .replace(/\\,/g, "</br>")
                .replace(/&nbsp;/g, " ")
                .replace(/<\/?(img|table)[^>]*>/g, ""); //去除图片、表格
              this.green.push(item);
            });
            res.data.data.classic.map((item) => {
              item.content = item.content
                .replace(/\s*/g, "")
                .replace(/<[^>]+>/g, "")
                .replace(/↵/g, "")
                .replace(/[\r\n]/g, "")
                .replace(/\\,/g, "</br>")
                .replace(/&nbsp;/g, " ")
                .replace(/<\/?(img|table)[^>]*>/g, ""); //去除图片、表格
              this.classic.push(item);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newss() {
      const that = this;
      const data = {
        page: 1,
        limit: 8,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/qx_news_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log("that.news", that.news);
            res.data.data.list.map((item) => {
              item.content = item.content
                .replace(/\s*/g, "")
                .replace(/<[^>]+>/g, "")
                .replace(/↵/g, "")
                .replace(/[\r\n]/g, "")
                .replace(/\\,/g, "</br>")
                .replace(/&nbsp;/g, " ")
                .replace(/<\/?(img|table)[^>]*>/g, ""); //去除图片、表格
              this.news.push(item);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fillterImg(data) {
      let informationArticle = data
        .replace(/<\/?(img)[^>]*>/gi, "")
        .replace(/<\/?[^>]*>/g, "");
      return informationArticle;
    },
    onRollEnd(page) {
      console.log("当前页面为", page);
    },
    onPointerMouseover(page) {
      page.event.target.title = "1";
    },
    toPage() {
      this.page = this.tempPage;
    },
    pushRouter(item, id) {
      this.$router.push({ path: item, query: { item: id } });
    },
    getType() {
      const that = this;
      getCate(1).then((res) => {
        if (res.code === 200) {
          console.log(res);
          that.typeList = res.data;
        }
      });
    },
    getpag3() {
      const that = this;
      const data = {
        page: 1,
        limit: 8,
        module_id: 1,
        cate_id: that.tabIndex,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.pag3 = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpag4() {
      const that = this;
      const data = {
        page: 1,
        limit: 4,
        module_id: 2,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.pag4 = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpag5() {
      const that = this;
      const data = {
        page: 1,
        limit: 3,
        module_id: 5,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.pag5 = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tolist(url, id) {
      this.$router.push({ path: url, query: { item: id } });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
    var resizeEvt =
      "orientationchange" in window ? "orientationchange" : "resize";
    var setFont = function () {
      // 获取设备屏幕的宽度
      var html = document.documentElement;
      var width = html.clientWidth; // 获取html的宽度
      // var height = document.documentElement.clientHeight
      // 判断一下，width值是否小于1024；是否大于1920
      if (width < 1024) {
        width = 1024;
      }
      if (width > 1920) {
        width = 1920;
      }
      // 计算html的fontSize值
      var fontSize = width / 192 + "px";
      // 设置html元素的font-size
      html.style.fontSize = fontSize;
      // var height = document.documentElement.clientHeight
      //   if (height <= 969) {
      //     document.querySelector('.database').style.height = '101rem'
      //     document.querySelector('.centermain').style.height = '50.6rem'
      //     document.getElementById('rightmain').style.height = '83.1rem'
      //     document.getElementById('c1').style.height = '51.7rem'
      //   }
    };
    setFont(); //自调用先生效
    // window.addEventListener(resizeEvt, setFont, false);
    // document.addEventListener('DOMContentLoaded', setFont, false);
    // 浏览器窗口大小改变的时候，重新设置html的fontSize
    window.onresize = function () {
      setFont();
    };
    this.getType();
    this.getpag3();
    this.getpag4();
    this.getpag5();
  },
};
</script>
<style lang="scss" scoped>
// 头部
.contact {
  img {
    display: inline-block;
    margin-left: 20px;
  }
  span {
    display: inline-block;
    margin-left: 13px;
    width: 114px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    // font-weight: 700;
    text-align: left;
  }
}
// 轮播图
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.block {
  width: 100%;
}
// 轮播图圆点
/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
  margin: 0 20px;
}
/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
  margin: 0 20px;
}
// 选择七星
.four_block {
  display: inline-block;
  margin-top: 97px;
  &:nth-child(2n-1) {
    margin-left: 160px;
  }
  img {
    display: inline-block;
    width: 93px;
    height: 96px;
  }
  .fours {
    display: inline-block;
    margin-left: 42px;
    .p_one {
      font-size: 26px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      margin-top: 0px;
      color: #666666;
      line-height: 23px;
      text-indent: 0em;
    }
    .p_two {
      width: 384px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #949494;
      margin-top: 24px;
      color: #666666;
      line-height: 23px;
      text-indent: 0em;
    }
  }
}
// 绿色展具案例
.bacc {
  background: #f6f9fa;
  width: 100%;
  padding-top: 96px;
  p {
    margin-top: 0px !important;
    font-size: 18px !important;
    color: #000 !important;
    text-indent: 0em !important;
  }
  .title {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.center {
  width: 1200px;
  margin: 0 auto;
  padding-top: 141px;

  .case {
    display: inline-block;
    width: 372px;
    background: #fff;
    padding-bottom: 32px;
    margin-left: 38px;
    &:nth-child(1) {
      margin-left: 0;
    }
    img {
      width: 372px;
      height: 279px;
    }
    .p1 {
      margin: 0 20px 0 20px;
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px solid #e6e6e6;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-indent: 0em;
    }
    .p2 {
      margin: 16px 25px 0 25px;
    }
    .p3 {
      display: block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      text-indent: 0em;
      &:hover {
        color: #ffffff;
        background: #1156be;
      }
    }
  }
}
.borr {
  border: 1px solid #e6e6e6;
}
//
.back {
  background: url("~@/assets/qixing/bg.png");
  width: 100%;
  // height: 380px;
  padding-bottom: 80px !important;
  padding-top: 37px;
  .title {
    margin-top: 0 !important;
    margin-bottom: 72px !important;
  }
}
.imgs {
  padding-top: 0 !important;
  display: flex;
  align-items: center;
  p {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    margin-top: 28px !important;
    text-indent: 0em !important;
  }
  img {
    width: 55px;
    height: 49px;
    align-items: center;
    margin-left: 32px;
    &:nth-child(2n) {
      width: 19px;
      height: 18px;
    }
  }
}
.oness {
  img {
    display: block;
    margin: 0 auto;
    margin-left: 32px;
  }
  p {
    margin-left: 25px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
  }
}
.img_one {
  margin-left: 0 !important;
}
.img_ones {
  margin-left: 8.5px !important;
}
// 合作企业
.cus {
  padding-top: 41px !important;
  img {
    margin-right: 15px;
    margin-top: 70px;
    &:nth-child(5n) {
      margin-right: 0px;
    }
  }
}
.conll {
  .title {
    margin-bottom: 0rem !important;
  }
}
// 新闻中心
.newss {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  width: 558px;
  position: relative;
  margin-top: 28px;
  &:nth-child(2n) {
    margin-left: 80px;
  }
  .left {
    display: inline-block;
    width: 100px;
    height: 109px;
    border: 4px solid #e6e6e6;
    margin-right: 29px;
    p {
      text-align: center;
      font-size: 36px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      margin-top: 23px !important;
      &:nth-child(2) {
        font-size: 18px !important;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        margin-top: 23px !important;
      }
    }
  }
  .right {
    display: inline-block;
    width: 350px;
    position: absolute;
    top: 21px;
    right: 70px;
    p {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      &:nth-child(2) {
        margin-top: 18px !important;
        display: block;
        opacity: 0.8;
        font-size: 14px !important;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
      }
    }
  }
}
.newss:hover {
  border: 1px solid #1156be;
}
.newss:hover .left {
  background: #1156be;
  border: 4px solid #1156be;
  .p1 {
    color: #fff !important;
  }
}
.newss:hover .right .p2 {
  color: #1156be !important;
}
.newss:hover .sanj {
  border-left-color: #1156be;
}
// 三角
.sanj {
  width: 0px;
  height: 0px;
  border: 8px solid #000;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #000;
  border-right-color: transparent;
  position: absolute;
  bottom: 10px;
  right: -40px;
}
// 图片
.pic {
  width: 100%;
  height: 380px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    &:nth-child(2) {
      position: absolute;
      right: 14%;
      bottom: 69px;
      width: 186px;
      height: 186px;
    }
  }
}
// 底部
.top {
  width: 100%;
  background: #252f35;
  padding: 34px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .xian {
    display: inline-block;
    width: 2px;
    height: 103px;
    background: #aaaaaa;
    margin-left: 21px;
    margin-right: 62px;
    &:nth-child(4) {
      margin-left: 60px;
    }
  }
  .juzhong {
    width: 1100px;
    margin: 0 auto;
    .inb {
      display: inline-block;

      .in_block {
        display: inline-block;
        &:nth-child(2) {
          margin-left: 46px;
        }
        img {
          width: 120px;
          height: 120px;
          margin-top: 13px;
        }
      }
      .spa {
        margin-top: 24px;
        &:nth-child(1) {
          margin-top: 0px;
        }
        span {
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin-left: 17px;
        }
      }
      span {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-left: 17px;
      }
      a {
        margin-top: 24px;
        display: block;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-left: 17px;
      }
      p {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
      }
    }
  }
}
.bottom {
  padding: 18px 0;
  a {
    font-size: 12px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    background: #121d25;
    text-align: center;
  }
}

// 佳朋
.home {
  /deep/.el-button {
    width: 99px;
    height: 30px;
    border: 1px solid #cfcfcf;
    padding: 7px 20px;
  }
}
.page3 h3,
.page4 h3,
.page5 h3,
.page6 h3 {
  font-size: 34px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  letter-spacing: 2px;
  span {
    font-size: 16px;
  }
}
.page1 {
  position: relative;
  // height: 100%;
  .header {
    height: 100px;
    // background-color: #000;
    .headCentet {
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        margin-right: 204px;
      }
      ul {
        display: flex;
        li {
          font-size: 18px;
          // color: #ffffff;
          line-height: 26px;
          width: 106px;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          &:hover {
            background: #1156be;
            color: #fff;
          }
        }
        .act {
          background: #e60412;
        }
      }
    }
  }
  .theme {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h3 {
      font-size: 72px;
      font-weight: 700;
      color: #ffffff;
      line-height: 110px;
      letter-spacing: 2px;
    }
    p {
      font-size: 38px;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 110px;
      letter-spacing: 1px;
      margin-bottom: 118px;
    }
    .arrow {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      border: 1px solid #fff;
      margin: 0 auto;
      line-height: 71px;
      img {
        vertical-align: middle;
      }
    }
  }
}
.page2 {
  padding-bottom: 182px;
  .title {
    margin-top: 9.7rem;
    font-size: 34px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 10.1rem;
    span {
      font-size: 16px;
      font-weight: bold;
      color: #999999;
    }
  }
  ul {
    display: flex;
    justify-content: space-around;
    li {
      h3 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 26px;
        cursor: pointer;
        span {
          font-size: 14px;
          font-weight: bold;
          color: #666666;
        }
      }
      div {
        cursor: pointer;
        width: 285px;
        height: 242px;
        margin-top: 45px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.6s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  p {
    margin-top: 65px;
    font-size: 18px;
    text-align: left;
    color: #666666;
    line-height: 23px;
    text-indent: 2em;
  }
  /deep/.el-button {
    float: right;
    margin-top: 51px;
  }
}
.page3 {
  padding-top: 5.4rem;
  .nav {
    display: flex;
    justify-content: space-between;
    margin-top: 7.1rem;
    li {
      width: 130px;
      height: 57px;
      border: 1px solid #ffffff;
      font-size: 20px;
      text-align: center;
      color: #ffffff;
      line-height: 57px;
      cursor: pointer;
      &:hover {
        background: #e60412;
        border-color: #e60412;
      }
    }
  }
  .imgbox {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      width: 284px;
      height: 23.8rem;
      border: 1px solid #ffffff;
      margin-top: 22px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  /deep/.el-button {
    margin-top: 44px;
    float: right;
    background: transparent;
    color: white;
  }
}
.page4 {
  padding-top: 60px;
  h3 {
    color: #333333;
    line-height: 35px;
    span {
      color: #999999;
    }
  }
  ul {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 543px;
      .innerBox {
        width: 543px;
        height: 31.1rem;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
        .textBox {
          padding: 0 27px;
          height: 100%;
          position: absolute;
          top: 100%;
          background: rgba($color: #000000, $alpha: 0.62);
          text-align: center;
          display: flex;
          align-items: center;
          transition: top 0.5s ease-out;
          /deep/p {
            font-size: 16px;
            color: #ffffff;
            line-height: 24px;
            text-align: left;
            margin-bottom: 27px;
          }
          /deep/ .el-button {
            color: white;
          }
        }
        &:hover {
          .textBox {
            top: 0;
          }
        }
      }
      > p {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 55px;
      }
    }
  }
  /deep/.el-button {
    background: transparent;
  }
  > .el-button {
    float: right;
    margin-top: 16px;
  }
}
.page5 {
  padding-top: 6.5rem;
  .newsCenter {
    margin-top: 7.9rem;
    width: calc(100%-86px);
    height: 65.1rem;
    background: rgba($color: #fff, $alpha: 0.27);
    padding: 0 38px 0 48px;
    .news {
      color: #ffffff;
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #e6e6e6;
      height: 17.2rem;
      padding: 0 40px;
      .redBox {
        width: 10.4rem;
        height: 10.3rem;
        background: #e60412;
        text-align: center;
        line-height: 4.2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h4 {
          font-size: 3.7rem;
          font-weight: 800;
          letter-spacing: 2px;
        }
        h5 {
          font-size: 2.1rem;
        }
      }
      p {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.8rem;
        margin-left: 4.8rem;
        cursor: pointer;
      }
    }
    /deep/.el-button {
      margin-top: 47px;
      float: right;
      background: transparent;
      color: white;
    }
  }
}
.page6 {
  padding-top: 55px;
  h3 {
    color: #333333;
    line-height: 35px;
    span {
      color: #999999;
    }
  }
  .iconBox {
    margin-top: 60px;
    text-align: center;
    margin-bottom: 30px;
    img {
      // height: 486px;
      // min-height: 322px;
    }
    ul {
      li {
        border-radius: 10px;
        border: 1px solid rgba(114, 180, 212, 0.6);
        cursor: pointer;
        min-height: 72px;
        max-width: 50%;
        margin: 0 auto;
        a {
          font-size: 34px;
          line-height: 72px;
          color: #333;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.toolTip {
  position: absolute;
  right: 40px;
  padding: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  &::after {
    content: "";
    display: block;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent transparent #000;
    position: absolute;
    right: -13px;
    top: 8px;
  }
}
.home .full-page-wrapper .pointer-wrapper ul > li {
  background: transparent;
  border: 1px solid #4fc3f7;
}
.home .full-page-wrapper .pointer-wrapper ul .active {
  background: #4fc3f7;
  border: 1px solid #4fc3f7;
  transform: scale(1);
}
.modal {
  p {
    background-color: transparent !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* 2行 */
    -webkit-box-orient: vertical;
    span {
      background-color: transparent !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.adv {
  width: 100%;
  margin: 0 auto;
  padding-top: 141px;
  // padding-bottom: 107px;
  p {
    &:nth-child(1) {
      font-size: 34px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    &:nth-child(2) {
      margin-top: 16px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      color: #999999;
      text-align: center;
    }
  }
}
.items {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.us {
  display: inline-block;
  // justify-content: center;
  // align-items: center;
  width: 556px;
  height: 362px;
  position: relative;
  margin-top: 64px;
  img {
    width: 100%;
    height: 100%;
  }
  h3 {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    bottom: 110px;
    left: 38px;
  }
  .xian {
    display: block;
    width: 33px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    left: 44px;
    bottom: 93px;
  }
  p {
    display: block;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    left: 39px;
    bottom: 61px;
  }
}
.blocks {
  margin-right: 20px;
  display: inline-block;
  margin-top: 84px;
  width: 285px;
  height: 382px;
  background: #ffffff;
  box-shadow: -8px 0px 14px 4px rgba(195, 192, 194, 0.2);
  img {
    width: 83px;
    height: 86px;
    display: block;
    margin: 0 auto;
    margin-top: 44px;
  }
  .Word {
    margin-top: 28px;
    width: 100%;
    height: 224px;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #3f7cf8;
    box-shadow: -8px 0px 32px 8px rgba(105, 102, 102, 0.2);
    h1 {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 62px;
      text-shadow: -8px 0px 32px 8px rgba(105, 102, 102, 0.2);
      text-align: center;
    }
    h2 {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 30px;
      text-shadow: -8px 0px 32px 8px rgba(105, 102, 102, 0.2);
    }
  }
  .lee {
    h2 {
      text-align: left;
      margin: 0 32px;
    }
  }
}
.blocks:nth-child(4) {
  margin-right: 0;
}
.green {
  background: #f6f9fa;
  height: 100vh;
  p {
    &:nth-child(1) {
      padding-top: 96px;
      font-size: 34px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      color: #333333;
      letter-spacing: 1.7px;
      text-align: center;
    }
    &:nth-child(2) {
      padding-top: 15px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      color: #999999;
      text-align: center;
    }
  }
}
.ccde {
  width: 375px;
  height: 540px;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding-bottom: 32px;
  margin-right: 38px;
  &:nth-child(3) {
    margin-right: 0;
  }
  .pic {
    width: 100%;
    height: 279px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .word {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: left;
      color: #333333;
      padding: 20px 0;
    }
    h2 {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 22px;
      width: 322px;
      height: 86px;
      margin-top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      /* 将对象作为弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 限制在一个块元素显示的文本的行数 */
      /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
      -webkit-line-clamp: 4;
      /* 设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-box-orient: vertical;
    }
    .xian {
      width: 335px;
      height: 1px;
      background: #e6e6e6;
    }
    .details {
      padding: 13px 32px;
      background: #ffffff;
      border: 1px solid #cccccc;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #999999;
      margin-top: 30px;
      &:hover {
        background: #1156be;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.pics {
  width: 104px;
  height: 28px;
  position: absolute;
  right: 361px;
  bottom: 44px;
}
.shang {
  padding-top: 58px;
}
.cases {
  background: #ffffff;
  height: 100vh;
  p {
    &:nth-child(1) {
      padding-top: 96px;
      font-size: 34px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      color: #333333;
      letter-spacing: 1.7px;
      text-align: center;
    }
    &:nth-child(2) {
      padding-top: 15px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      color: #999999;
      text-align: center;
    }
  }
}
.proc {
  width: 100%;
  height: 300px;
  padding-top: 44px;
  background: url("~@/assets/qixingp/ti@2x.png");
  background-size: 100% 100%;
  p {
    &:nth-child(1) {
      font-size: 34px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: center;
      color: #ffffff;
      line-height: 26px;
      letter-spacing: -1.36px;
    }
    &:nth-child(2) {
      margin-top: 20px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 26px;
      letter-spacing: -0.64px;
    }
  }
}
.proce {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  p {
    margin-top: 0px !important;
    font-size: 14px !important;
    font-family: Source Han Sans CN !important;
    font-weight: 500 !important;
    color: #ffffff !important;
  }
  img {
    width: 55px;
    height: 49px;
    &:nth-child(2n) {
      width: 19px;
      height: 18px;
      margin-top: -30px;
      margin-left: 32px;
      margin-right: 32px;
    }
  }
}
.coop {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 45px;
  p {
    &:nth-child(1) {
      font-size: 34px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      color: #333333;
      letter-spacing: 1.7px;
      text-align: center;
    }
    &:nth-child(2) {
      margin-top: 21px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      color: #999999;
      text-align: center;
    }
  }
  img {
    width: 1100px;
    height: 450px;
    margin: 0 auto;
    margin-top: 20px;
    // width: 228px;
    // height: 107px;
    // border: 1px solid #e6e6e6;
    // margin-top: 45px;
    // margin-right: 15px;
    // &:nth-child(5n) {
    //   margin-right: 0;
    // }
    // &:nth-child(12) {
    //   margin-right: 0;
    // }
  }
}
.newsc {
  width: 100%;
  height: 100vh;
  padding-top: 95px;
  background: #f7f8f8;
  p {
    &:nth-child(1) {
      font-size: 34px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      text-align: center;
      color: #333333;
      letter-spacing: 1.7px;
    }
    &:nth-child(2) {
      margin-top: 15px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      text-align: center;
      color: #999999;
    }
  }
  .journalism {
    width: 1200px;
    margin: 0 auto;
    margin-top: 142px;
    .contents {
      display: inline-block;
      width: 558px;
      height: 110px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      margin-bottom: 28px;
      margin-right: 80px;
      &:nth-child(2n) {
        margin-right: 0px;
      }
      &:hover {
        border: 1px solid #1156be;
        box-shadow: -8px 0px 15px 8px rgba(229, 199, 172, 0.2);
        cursor: pointer;
        .time {
          background: #1156be;
          border: 4px solid #1156be;
          p {
            color: #fff;
          }
          span {
            color: #fff;
          }
        }
        .words {
          h1 {
            color: #1156be;
          }
          .Jump {
            span {
              color: #1156be;
            }
            .sanjiao {
              border-left: 8px solid #1156be;
            }
          }
        }
      }
      .time {
        display: inline-block;
        width: 17.8%;
        height: 102px;
        background: #ffffff;
        border: 4px solid #e6e6e6;
        p {
          margin-top: 15px;
          font-size: 36px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          text-align: center;
          color: #878787;
        }
        span {
          display: block;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: center;
          color: #878787;
        }
      }
      .words {
        // display: inline-block;
        width: 70%;
        float: right;
        padding: 17px 30px;
        h1 {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }
        .Jump {
          margin-top: 18px;
          display: flex;
          align-items: center; //使内部元素垂直居中
          justify-content: center; //使内部元素水平居中
          justify-content: space-between; //两端对齐
          span {
            width: 354px;
            // display: block;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: left;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 2行 */
            -webkit-box-orient: vertical;
          }
          .sanjiao {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-left: 8px solid #333333;
            border-bottom: 7px solid transparent;
          }
        }
      }
    }
    h3 {
      margin-top: 30px;
      float: right;
      padding: 0px 59px;
      border: 1px solid #cccccc;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #999999;
      line-height: 39px;
      letter-spacing: 1.12px;
      &:hover {
        color: #1156be;
        border: 1px solid #1156be;
        cursor: pointer;
      }
    }
  }
}
.demand {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -3px;
  height: 315px;
  background: #374046;
  padding-top: 35px;
  span {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
  }
  .disi {
    display: inline-block;
    margin-left: 110px;
    .inp1 {
      margin-right: 102px;
      display: flex;
      align-items: center;
      // justify-content: center;
      &:nth-child(3) {
        margin-right: 0px;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
      }
      input {
        padding-left: 5px;
        width: 194px;
        height: 44px;
        outline: none;
      }
    }
    .inp2 {
      display: flex;
      align-items: center;
      margin-top: 35px;
      p {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
      }
      textarea {
        padding-left: 5px;
        width: 92.5%;
        height: 106px;
        outline: none;
      }
    }
  }
}
.fabu {
  padding: 11px 44px;
  background: #1156be;
  font-size: 16px !important;
  font-family: PingFang SC, PingFang SC-Medium !important;
  font-weight: 500 !important;
  text-align: left !important;
  color: #ffffff !important;
  float: right;
  margin-top: 27px;
  cursor: pointer;
}

// 底部
.top {
  width: 100%;
  background: #252f35;
  padding: 34px 0;
  .xian {
    display: inline-block;
    width: 2px;
    height: 103px;
    background: #aaaaaa;
    margin-left: 21px;
    margin-right: 62px;
    &:nth-child(4) {
      margin-left: 60px;
    }
  }
  .juzhong {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .inb {
      display: inline-block;
      .in_block {
        display: inline-block;
        &:nth-child(2) {
          margin-left: 46px;
        }
        img {
          width: 120px;
          height: 120px;
          margin-top: 13px;
        }
      }
      .spa {
        margin-top: 24px;
        display: flex;
        align-items: center;
        // justify-content: center;
        img {
          width: 14px;
          height: 18px;
        }
        span {
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin-left: 17px;
        }
      }
      span {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-left: 17px;
      }
      a {
        margin-top: 24px;
        display: block;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-left: 17px;
      }
      p {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
.bottom {
  padding: 18px 0;
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  background: #121d25;
  text-align: center;
}
.marr {
  margin-top: 0;
}
.shuxian {
  width: 1px;
  height: 103px;
  background: #aaaaaa;
  margin: 0 50.5px;
}

.bot {
  // width: 100%;
  // width: calc( - 50px);
  width: 100vw;
  position: fixed;
  bottom: 0;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
}
.service {
  position: absolute;
  top: 0;
  width: 100%;
}
.fll {
  display: flex;
  align-items: center;
  img {
    width: 33px;
    height: 33px;
  }
}
.wybzd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.posr {
  position: relative;
  height: 39.22vh;
}
.post {
  position: absolute;
  top: 15vh;
  right: 30vh;
  z-index: 9999;
  width: 19.2vh;
  height: 19.2vh;
}
.centers {
  width: 1200px;
  margin: 0 auto;
  text-align: right;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #999999;
  margin-top: 3vh;
  span {
    cursor: pointer;
  }
}
</style>